



















import Vue from 'vue'
export default Vue.extend({
  name: 'PhiFormulaInner',
  props: {
    zName: {
      type: String,
      default: 'z'
    }
  }
})

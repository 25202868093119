










































import Vue from 'vue'

import zData from './zTable.json'

export default Vue.extend({
  name: 'ZTable',
  data () {
    return {
      rows: zData
    }
  },
  props: {
    zValue: Number,
    zValueTwo: {
      type: Number,
      required: false
    }
  },
  mounted () {
    this.drawBoxes(this.zValue, 0.00, false)
    this.drawBoxes(this.zValueTwo, 0.00, true)
  },
  methods: {
    addClass (id: string, className: string) {
      try {
        // @ts-ignore
        document.getElementById(id).classList.add(className)
      } catch {
        console.log('Failed to add class ' + className + ' to element with id ' + id)
      }
    },
    removeClass (id: string, className: string) {
      try {
        // @ts-ignore
        document.getElementById(id).classList.remove(className)
      } catch {
        console.log('Failed to add class ' + className + ' to element with id ' + id)
      }
    },
    drawBoxes (newVal: number, oldVal: number, useZValueTwo: boolean) {
      newVal = Math.abs(newVal)
      oldVal = Math.abs(oldVal)
      console.log('draw box for ' + newVal)
      console.log(oldVal)
      console.log(this.zValue)

      if (oldVal > 4) {
        oldVal = 4
      }
      if (newVal > 4) {
        newVal = 4
      }
      const roundedNew = Number(newVal).toFixed(2).toString()
      const roundedOld = Number(oldVal).toFixed(2).toString()
      const xAxisValue = 'x-0.0' + roundedNew[3]
      const yAxisValue = 'y-' + roundedNew.substring(0, 3)
      const xAxisValueOld = 'x-0.0' + roundedOld[3]
      const yAxisValuesOld = 'y-' + roundedOld.substring(0, 3)

      if (useZValueTwo) {
        if (oldVal !== Math.abs(this.zValue)) {
          this.removeClass(roundedOld, 'active')
          this.removeClass(xAxisValueOld, 'active-border')
          this.removeClass(yAxisValuesOld, 'active-border')
        }
      } else {
        if (oldVal !== Math.abs(this.zValueTwo)) {
          this.removeClass(roundedOld, 'active')
          this.removeClass(xAxisValueOld, 'active-border')
          this.removeClass(yAxisValuesOld, 'active-border')
        }
      }

      this.addClass(roundedNew, 'active')
      this.addClass(xAxisValue, 'active-border')
      this.addClass(yAxisValue, 'active-border')

      if (useZValueTwo) {
        // @ts-ignore
        const probabilityTableTwo = Number(document.getElementById(roundedNew).innerHTML)
        this.$store.commit('setProbabilityTableTwo', probabilityTableTwo)
      } else {
        // @ts-ignore
        const probabilityTableOne = Number(document.getElementById(roundedNew).innerHTML)
        this.$store.commit('setProbabilityTable', probabilityTableOne)
      }
    }
  },
  watch: {
    zValue: function (newVal, oldVal) {
      this.drawBoxes(newVal, oldVal, false)
    },
    zValueTwo: function (newVal, oldVal) {
      console.log('updating')
      this.drawBoxes(newVal, oldVal, true)
    }
  }
})





























































import Vue from 'vue'

export default Vue.extend({
  name: 'Exercises',
  data: () => ({
    index: 0,
    showAnswer: false,
    exercises: [
      {
        text: 'Eine Maschine produziert 500 mm lange Schrauben mit einer Standardabweichung von 10 mm. Die Länge der Schrauben kann als normalverteilt angesehen werden. Berechne die Wahrscheinlichkeit dafür, dass eine Schraube kürzer ist als 485 mm.',
        probability: 6.7
      },
      {
        text: 'Für den Ebolavirus wird nach Einschätzung der Statistiker bei 8 % der Bevölkerung eine ärztliche Behandlung notwendig sein. Ein Großhandel möchte für die Apotheken einer Kreisstadt mit 20 000 Einwohnern Behandlungsmaterialien im Voraus bestellen. Mit welcher Wahrscheinlichkeit werden mindestens 1350 Behandlungsmaterialien benötigt?',
        probability: 100
      },
      {
        text: 'Eine Maschine produziert 500 mm lange Schrauben mit einer Standardabweichung von 10 mm. Berechne die Wahrscheinlichkeit dafür, dass eine Schraube höchstens 501 mm und mindestens 499 mm lang ist.',
        probability: 8
      },
      {
        text: 'Für den Ebolavirus wird nach Einschätzung der Statistiker bei 8 % der Bevölkerung eine ärztliche Behandlung notwendig sein. Ein Großhandel möchte für die Apotheken einer Kreisstadt mit 20 000 Einwohnern Behandlungsmaterialien im Voraus bestellen. Mit welcher Wahrscheinlichkeit werden mindestens 1350 Behandlungsmaterialien benötigt? Mit welcher Wahrscheinlichkeit weicht die Zahl der benötigten Behandlungsmaterialien um nicht mehr als die Standardabweichung vom Erwartungswert ab?',
        probability: 68.76
      }
    ]
  })
})
























import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'AValueInput',
  props: ['name', 'step', 'setMethod', 'min', 'max', 'storeName'],
  data () {
    return {
      value: this.$store.state[this.storeName],
      start: this.$store.state.aValueStart,
      end: this.$store.state.aValueEnd
    }
  },
  methods: {
    updateAValue () {
      this.$store.commit('setAValue', this.value)
    },
    updateAValueStart () {
      if (this.start >= this.end) {
        this.start = Math.round((this.end - 0.1) * 100) / 100
      }

      this.$store.commit('setAValueStart', this.start)
    },
    updateAValueEnd () {
      this.start = parseFloat(this.start)
      this.end = parseFloat(this.end)

      if (this.end <= this.start) {
        this.end = Math.round((this.start + 0.1) * 100) / 100
      }
      this.$store.commit('setAValueEnd', this.end)
    }
  },
  computed: mapState([
    'aValueStart', 'aValueEnd', 'isBetween'
  ])
})

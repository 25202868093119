
















import Vue from 'vue'
import { mapState } from 'vuex'
import SmallerThanProcedure from './calculationProcedures/smallerThan.vue'
import GreaterThanProcedure from './calculationProcedures/greaterThan.vue'
import InBetweenProcedure from './calculationProcedures/inBetween.vue'

export default Vue.extend({
  name: 'Calculation',
  components: {
    SmallerThanProcedure,
    GreaterThanProcedure,
    InBetweenProcedure
  },
  data () {
    return {
      //
    }
  },
  // updated () {
  //   // @ts-ignore
  //   window.MathJax.typeset()
  // },
  computed: mapState([
    'isGreater', 'isSmaller', 'isBetween', 'chartWidth'
  ])
})






















































import Vue from 'vue'
import VueQrcode from 'vue-qrcode'

export default Vue.extend({
  name: 'Settings',
  data () {
    return {
      showQR: false
    }
  },
  components: {
    VueQrcode
  },
  methods: {
    updateMode: function (mode: string) {
      this.$store.commit('setMode', mode)
    },
    copy: function () {
      navigator.clipboard.writeText(this.shareLink).then(function () {
        console.log('Async: Copying to clipboard was successful!')
      }, function (err) {
        console.error('Async: Could not copy text: ', err)
      })
    }
  },
  computed: {
    isGreater () {
      return this.$store.state.isGreater
    },
    isSmaller () {
      return this.$store.state.isSmaller
    },
    isBetween () {
      return this.$store.state.isBetween
    },
    shareLink () {
      const baseUrl = `${window.location.host}#/learn?mean=${this.$store.state.mean}&std=${this.$store.state.std}`

      if (this.$store.state.isBetween) {
        return baseUrl + `&mode=inBetween&a1=${this.$store.state.aValueStart}&a2=${this.$store.state.aValueEnd}`
      } else if (this.$store.state.isGreater) {
        return baseUrl + `&mode=greater&a=${this.$store.state.aValue}`
      } else {
        return baseUrl + `&mode=smaller&a=${this.$store.state.aValue}`
      }
    }
  }
})

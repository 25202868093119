





















import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'PhiFormulaInnerInput',
  props: {
    zName: {
      type: String,
      default: 'z'
    }
  },
  data () {
    return {
      zValue: 0
    }
  },
  mounted () {
    this.calcZValue()
  },
  methods: {
    calcZValue () {
      this.zValue = ((this.aValue as any) - (this.mean as any)) / (this.std as any)
      this.$store.commit('setZValue', this.zValue)
    }
  },
  computed: mapState([
    'mean', 'std', 'aValue'
  ]),
  watch: {
    mean: function (val) {
      this.calcZValue()
    },
    std: function (val) {
      this.calcZValue()
    },
    aValue: function (val) {
      this.calcZValue()
    }
  }
})

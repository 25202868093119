
















import Vue from 'vue'
import { mapState } from 'vuex'
import SimpleSmallerThan from './simpleCalc/simpleSmallerThan.vue'
import SimpleGreaterThan from './simpleCalc/SimpleGreaterThan.vue'
import SimpleInBetween from './simpleCalc/SimpleInBetween.vue'

export default Vue.extend({
  name: 'SimpleCalculation',
  components: {
    SimpleSmallerThan,
    SimpleGreaterThan,
    SimpleInBetween
  },
  computed: mapState([
    'isGreater', 'isSmaller', 'isBetween', 'chartWidth'
  ])
})

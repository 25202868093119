








import Vue from 'vue'

export default Vue.extend({
  name: 'InputSlider',
  props: ['name', 'step', 'setMethod', 'min', 'max', 'storeName'],
  data () {
    return {
      value: this.$store.state[this.storeName]
    }
  },
  methods: {
    update () {
      console.log('updated ')
      this.$store.commit(this.setMethod, this.value)
    }
  }
})



































import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

import Chart from '@/components/Chart.vue'
import Calculation from '@/components/Calculation.vue'
import SimpleCalculation from '@/components/SimpleCalculation.vue'

@Component({
  data () {
    return {
      tab: null
    }
  },
  components: {
    Chart,
    Calculation,
    SimpleCalculation
  },
  computed: {
    ...mapState([
      'chartWidth'
    ])
  }
})
export default class Home extends Vue {}

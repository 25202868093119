































































































import Vue from 'vue'
import { mapState } from 'vuex'

import DefaultChart from '../DefaultChart.vue'

export default Vue.extend({
  name: 'SimpleGreaterThan',
  components: {
    DefaultChart
  },
  computed: {
    ...mapState(['mean', 'std', 'aValue', 'aValueStart', 'aValueEnd', 'isGreater'])
  }
})

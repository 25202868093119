













































































































































































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import ZTable from '../math/zTable.vue'
import PhiFormulaBetween from '../math/phiFormulaInBetween.vue'
import PhiFormulaInner from '../math/phiFormulaInner.vue'
import PhiFormulaInnerInputBetween from '../math/phiFormulaInnerInputBetween.vue'

export default Vue.extend({
  name: 'GreaterThanProcedure',
  components: {
    ZTable,
    PhiFormulaBetween,
    PhiFormulaInner,
    PhiFormulaInnerInputBetween
  },
  data () {
    return {
      //
    }
  },
  computed: mapState([
    'mean', 'std', 'aValue', 'aValueStart', 'aValueEnd', 'isGreater', 'isSmaller', 'isBetween', 'probability', 'zValue', 'zValueTwo', 'probabilityTable', 'probabilityTableTwo', 'chartWidth'
  ])
})
